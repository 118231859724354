export const homeTextConfig =   {
    bannerTitle:"日新サービス", //大标题
    bannerDesc:"は、お客様にご満足いただけるよう製品・サービスの品質向上に努めています。", //标题描述
    bannerButtonText:"業務紹介", //按钮文字
    //主模块数据
    bodyData: [
      {
        index:"01", //序号
        reverse:false, //左右排版
        backgroundColor:'#FFFFFF', //背景色
        imageUrl:require('@/assets/images/home/img_iot.png'), //图片链接
        title:'IoT研究開発', //标题
        desc:'IoT（モノのインターネット、モノのデジタル化・ネットワーク化）M2Mシステムの企画から開発・運用や支援サポートができる人材が在籍しております。', //描述
        toUrl:'/service' //跳转链接
      },
      {
        index:"02",
        reverse:true,
        backgroundColor:'#EFEFFF',
        imageUrl:require('@/assets/images/home/img_qukuailian.png'),
        title:'ブロックチェイン',
        desc:'クラウド・ベースのプラットフォームであり、ブロックチェーンのアプリケーションを容易に開発、運用、および拡張するためのツールを提供します。',
        toUrl:'/service/btc'
      },
      {
        index:"03",
        reverse:false,
        backgroundColor:'#FFFFFF',
        imageUrl:require('@/assets/images/home/img_xinyongka.png'),
        title:'クレジットカード',
        desc:'業界予測では、中国は2020年には世界最大の銀行カード市場となる。弊社のクレジットカード業界の専門家より貴社の中国市場への進出をサポートします。',
        toUrl:'/service/creditcard'
      },
      {
        index:"04",
        reverse:true,
        backgroundColor:'#F0F6FF',
        imageUrl:require('@/assets/images/home/img_xitongkaifa.png'),
        title:'システム開発',
        desc:'情報システムの新規開発や既存システムのマイグレーション・リプレイスまで、事前のコンサルティングから設計・製造、その後の運用サポートに至るまで幅広く対応できます。',
        toUrl:'/service/develop'
      },
      {
        index:"05",
        reverse:false,
        backgroundColor:'#FFFFFF',
        imageUrl:require('@/assets/images/home/img_car.png'),
        title:'SMARTCAR',
        desc:'SMARTCARは、車両からの詳細な情報をクラウド上で管理・分析する運行管理サービスです。専用端末やスマートフォンを利用し、車両位置情報をクラウドに送信します。',
        toUrl:'/service/smartcar'
      }
    ],
    // 品牌
    brandData:[
      {
        imageUrl:require('@/assets/images/home/logo_18@2x.png'),
        url:'https://www.basenet.co.jp/'
      },
      {
        imageUrl:require('@/assets/images/home/logo_16@2x.png'),
        url:'https://www.kcs.co.jp/'
      },
      {
        imageUrl:require('@/assets/images/home/logo_19@2x.png'),
        url:'https://www.fsi.co.jp/'
      },
      // {
      //   imageUrl:require('@/assets/images/home/logo_4@2x.png'),
      //   url:'https://www.biz-connect.jp/'
      // },
      {
        imageUrl:require('@/assets/images/home/logo_7@2x.png'),
        url:'http://www.fk-j.co.jp/'
      },
      {
        imageUrl:require('@/assets/images/home/logo_17@2x.jpeg'),
        url:'https://www.fujitsu.com/jp/'
      },
      {
        imageUrl:require('@/assets/images/home/logo_15@2x.png'),
        url:'https://www.fujitsu.com/jp/group/fap/'
      },
      {
        imageUrl:require('@/assets/images/home/logo_14@2x.png'),
        url:'https://www.fujitsu.com/jp/group/fsweb/'
      },
      {
        imageUrl:require('@/assets/images/home/logo_6@2x.png'),
        url:'https://www.hyron.co.jp/'
      },
      // {
      //   imageUrl:require('@/assets/images/home/logo_2@2x.png'),
      //   url:'https://microworld.co.jp/'
      // },
      {
        imageUrl:require('@/assets/images/home/logo_8@2x.png'),
        url:'http://www.sjf.co.jp/'
      },
      {
        imageUrl:require('@/assets/images/home/logo_9@2x.png'),
        url:'https://www.softroad.co.jp/TOPICS/'
      },
      {
        imageUrl:require('@/assets/images/home/logo_10@2x.png'),
        url:'https://www.trex-group.com/'
      },
      {
        imageUrl:require('@/assets/images/home/logo_5@2x.png'),
        url:'http://xsbs.co.jp/'
      },
      {
        imageUrl:require('@/assets/images/home/logo_3@2x.png'),
        url:'http://www.yourfuture.jp/'
      }, 

      {
        imageUrl:require('@/assets/images/home/logo_20@2x.png'),
        url:'https://www.systemio.co.jp/'
      },
      // {
      //   imageUrl:require('@/assets/images/home/logo_12@2x.png'),
      //   url:'https://www.tis.co.jp/'
      // },
      // {
      //   imageUrl:require('@/assets/images/home/logo_13@2x.png'),
      //   url:'https://ari-jp.com/'
      // },

 
    ]
}
export default {}
