<template>
  <div class="home">
    <NavBar :isHome='true' />
    <div class="container-fluid home-banner">
      <div class="row">
        <p class="home-banner-title">{{homeTextConfig.bannerTitle}}</p>
        <p class="home-banner-desc">{{homeTextConfig.bannerDesc}}</p>
        <button @click="$router.push('/service')" type="button" class="home-banner-btn btn btn-warning">{{homeTextConfig.bannerButtonText}}</button>
      </div>
    </div>
    <div class="container-fluid home-body">
      <div class="row" :style="{backgroundColor:item.backgroundColor}" v-for="(item, index) in homeTextConfig.bodyData" :key="index">
        <div v-if="!item.reverse" class="col-md-6 col-sm-12 home-body-image">
          <img draggable="false" :src="item.imageUrl" alt="">
        </div>
        <div class="col-md-6 col-sm-12 home-body-text">
          <div>{{item.title}}</div>
          <span class="home-body-text-index">{{item.index}}</span>
          <p>{{item.desc}}</p>
          <button @click="goToUrl(item.toUrl)" type="button" class="btn btn-primary">詳細情報
            <img draggable="false" src="@/assets/images/home/jiantouyou@2x.png" alt="">
          </button>
        </div>
        <div  v-if="item.reverse" class="col-md-6 col-sm-12 home-body-image">
          <img draggable="false" :src="item.imageUrl" alt="">
        </div>
      </div>
    </div>
    <div class="brand-container">
      <div class="col-12 brand-container-title">パートナー</div>
      <div class="brand-array">
        <div @click="gotoUrl(item.url)" v-for="(item, index) in homeTextConfig.brandData" :key="index">
            <img draggable="false" :src="item.imageUrl" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {homeTextConfig} from "@/config/Home"
import NavBar from '@/components/NavBar'
export default {
  name: 'home',
  components: {
    NavBar
  },
  data(){
    return {
      homeTextConfig:homeTextConfig,
    }
  },
  methods:{
    gotoUrl(url){
      window.location = url
    },
    goToUrl(url){
      this.$router.push(url)
    },
  }
}
</script>
<style>
@media screen and (max-width: 1920px) and (min-width: 768px) {
   .home-banner{
    height: calc(100vw/1920*600);
    background-image: url('../assets/images/home/banner.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .home-banner div{
    width: calc(100vw/1920*408);
    text-align: left;
    position: absolute;
    top: calc(100vw/1920*192);
    left:calc(100vw/1920*360);
  }
  .home-banner-title{
    font-size:calc(100vw/1920*30);
    font-family:'Noto Serif JP',YuGothic, "游ゴシック体", serif;
    font-weight:700;
    color:rgba(255,255,255,1);
    line-height:60px;
    text-shadow:0px 2px 2px rgba(0,0,0,0.15);
  }
  .home-banner-desc{
    font-size:calc(100vw/1920*16);
    font-family:YuGothic, "游ゴシック体", "Noto Sans Japanese";
    font-weight:400;
    color:rgba(255,255,255,1);
    line-height:32px;
    text-shadow:0px 1px 1px rgba(0,0,0,0.5);
  }
  .home-banner-btn{
    width: calc(100vw/1920*140);
    height: calc(100vw/1920*50);
    margin-top: calc(100vw/1920*46);
    font-size:calc(100vw/1920*18);
    font-family:Noto Serif JP,YuGothic,serif;
    font-weight:500;
    color:rgba(255,255,255,1);
    line-height:25px;
  }
  .home-body>div{
    height: 440px;
    display: flex;
    padding: 10px calc(100vw/1920*360) 0 calc(100vw/1920*360);
  }
  .home-body-image img{
    width: calc(100vw/1920*567);
    height: calc(100vw/1920*421);
    margin-top: calc(100vw/1920*40);
  }
  .home-body-text{
    margin-top: 20px;
  }
  .home-body-text div{
    text-align: left;
    height: 133px;
    font-size:24px;
    font-family:'Noto Serif JP',YuGothic, "游ゴシック体", serif;
    font-weight:bold;
    color:rgba(2,98,255,1);
    line-height:133px;
  }
  .home-body-text-index{
    width:89px;
    height:89px;
    font-size:80px;
    font-family:'Noto Serif JP',YuGothic, "游ゴシック体", serif;
    font-weight:normal;
    color:rgba(2,98,255,1);
    line-height:92px;
    position: relative;
    right: calc(100vw/1920*200);
    bottom: 110px;
    opacity: 0.1;
  }
  .home-body-text p{
    text-align:left;
    width: calc(100vw/1920*558);
    font-size:18px;
    font-family:'Noto Serif JP',YuGothic, "游ゴシック体", serif;
    color:rgba(102,102,102,1);
    line-height:36px;
    position: relative;
    bottom: 100px;
  }
  .home-body-text button{
    width: 140px;
    height:40px;
    position: relative;
    right: calc(100vw/1920*190);
    bottom: 80px;
  }
  .home-body-text button img{
    width: 18px;
    height: 18px;
    position: relative;
    bottom: 1px;
  }
  .brand-container{
    width: 100vw;
    flex-wrap: wrap;
    padding: 40px calc(100vw/1920*330);
    background:rgba(245,245,249,1);
  }
  .brand-container-title{
    text-align: center;
    height:42px;
    font-size:30px;
    font-family:Noto Serif JP,YuGothic,serif;
    font-weight:700;
    color:rgba(2,98,255,1);
    line-height:42px;
  }
  .brand-array{
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
  }
  .brand-array div{
    cursor: pointer;
    margin: 10px;
    width: calc(100vw/1920*224);
    height: calc(100vw/1920*140);
    background:rgba(255,255,255,1);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s;
  }
  .brand-array div:hover{
    box-shadow:0px 0px 15px 0px rgba(0,0,0,0.15);
    transform: translateY(-10px);
    transition: all .5s;
  }
  .brand-array div img{
    width: calc(100vw/1920*182);;
  }
}
@media screen and (min-width: 1920px){
   .home-banner{
    height: 600px;
    background-image: url('../assets/images/home/banner.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .home-banner div{
    width: 408px;
    text-align: left;
    position: absolute;
    top: 192px;
    left:360px;
  }
  .home-banner-title{
    font-size:30px;
    font-family:Noto Serif JP,YuGothic,serif;
    font-weight:700;
    color:rgba(255,255,255,1);
    line-height:60px;
    text-shadow:0px 2px 2px rgba(0,0,0,0.15);
  }
  .home-banner-desc{
    font-size:16px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(255,255,255,1);
    line-height:32px;
    text-shadow:0px 1px 1px rgba(0,0,0,0.5);
  }
  .home-banner-btn{
    width: 140px;
    height: 50px;
    margin-top: 46px;
    font-size:18px;
    font-family:Noto Serif JP,YuGothic,serif;
    font-weight:500;
    color:rgba(255,255,255,1);
    line-height:25px;
  }
  .home-body>div{
    height: 440px;
    display: flex;
    padding: 10px 360px 0 360px;
  }
  .home-body-image img{
    width: 567px;
    height: 421px;
    margin-top: 40px;
  }
  .home-body-text{
    margin-top: 20px;
  }
  .home-body-text div{
    text-align: left;
    height: 133px;
    font-size:24px;
    font-family:HelveticaNeue-Bold,HelveticaNeue;
    font-weight:bold;
    color:rgba(2,98,255,1);
    line-height:133px;
  }
  .home-body-text-index{
    width:89px;
    height:89px;
    font-size:80px;
    font-family:Arial-BoldMT,Arial;
    font-weight:normal;
    color:rgba(2,98,255,1);
    line-height:92px;
    position: relative;
    right: 200px;
    bottom: 110px;
    opacity: 0.1;
  }
  .home-body-text p{
    text-align:left;
    width: 558px;
    font-size:18px;
    font-family:HelveticaNeue;
    color:rgba(102,102,102,1);
    line-height:36px;
    position: relative;
    bottom: 100px;
  }
  .home-body-text button{
    width: 140px;
    height:40px;
    position: relative;
    right: 190px;
    bottom: 80px;
  }
  .home-body-text button img{
    width: 18px;
    height: 18px;
    position: relative;
    bottom: 1px;
  }
  .brand-container{
    width: 100vw;
    flex-wrap: wrap;
    padding: 40px 330px;
    background:rgba(245,245,249,1);
  }
  .brand-container-title{
    text-align: center;
    height:42px;
    font-size:30px;
    font-family:PingFangSC-Semibold,PingFang SC;
    font-weight:700;
    color:rgba(2,98,255,1);
    line-height:42px;
  }
  .brand-array{
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
  }
  .brand-array div{
    cursor: pointer;
    margin: 10px;
    width: 224px;
    height: 140px;
    background:rgba(255,255,255,1);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s;
  }
  .brand-array div:hover{
    box-shadow:0px 0px 15px 0px rgba(0,0,0,0.15);
    transform: translateY(-10px);
    transition: all .5s;
  }
  .brand-array div img{
    width: 182px;
  }
}
@media screen and (max-width: 768px){
   .home-banner{
    height: calc(100vw/1920*600);
    background-image: url('../assets/images/home/banner.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .home-banner div{
    width: calc(100vw/1920*408);
    text-align: left;
    position: absolute;
    top: calc(100vw/1920*192);
    left:calc(100vw/1920*360);
  }
  .home-banner-title{
    display: none;
  }
  .home-banner-desc{
    display: none;
  }
  .home-banner-btn{
    display: none;
  }
  .home-body>div{
    padding: 20px;
    display: flex;
  }
  .home-body-image img{
    width: 70vw;
    height: 50vw;
  }
  .home-body-text{
     height: 220px;
  }
  .home-body-text div{
    font-family:HelveticaNeue-Bold,HelveticaNeue;
    font-weight:bold;
    color:rgba(2,98,255,1);
    height: 100px;
    line-height: 66px;
    text-align: center;
    font-size: 16px;
  }
  .home-body-text-index{
    width:89px;
    height:89px;
    font-family:Arial-BoldMT,Arial;
    font-weight:normal;
    color:rgba(2,98,255,1);
    line-height:92px;
    position: relative;
    opacity: 0.1;
    right: 0px;
    bottom: 112px;
    font-size: 40px;
  }
  .home-body-text p{
    font-size: 12px;
    line-height: 18px;
    text-align:left;
    font-family:HelveticaNeue;
    color:rgba(102,102,102,1);
    position: relative;
    bottom: 100px;
  }
  .home-body-text button{
    width: 140px;
    height:40px;
    position: relative;
    right: calc(100vw/1920*190);
    bottom: 80px;
  }
  .home-body-text button img{
    width: 18px;
    height: 18px;
    position: relative;
    bottom: 1px;
  }
  .brand-container{
    width: 100vw;
    flex-wrap: wrap;
    padding:  25px;
    background:rgba(245,245,249,1);
  }
  .brand-container-title{
    text-align: center;
    height:42px;
    font-size:16px;
    font-family:PingFangSC-Semibold,PingFang SC;
    font-weight:700;
    color:rgba(2,98,255,1);
    line-height:42px;
  }
  .brand-array{
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .brand-array div{
    cursor: pointer;
    margin: 10px;
    width: calc(100vw/1920*224);
    height: calc(100vw/1920*140);
    background:rgba(255,255,255,1);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s;
  }
  .brand-array div:hover{
    box-shadow:0px 0px 15px 0px rgba(0,0,0,0.15);
    transform: translateY(-10px);
    transition: all .5s;
  }
  .brand-array div img{
    width: calc(100vw/1920*182);;
  }
  .btn-primary{
      width: 100px !important;
      height: 33px !important;
      font-size: 12px !important;
      right: unset !important;
  }
}

</style>
